import { cx } from "@linaria/core";
import { FC, memo, MouseEvent } from "react";
import { useAppDispatch } from "@/hooks/redux";
import { modalsAlias, setIsShowModal } from "@/store/reducers/commonSlice";
import { Container } from "@/styles/utils/StyledGrid";
import { cssAuthGuardInvalid } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { cssIsActive, cssItem, MenuBar, StyledCaption, StyledFooterBar } from "./Styled";
import { MenuLinkIconI } from "./types";
export const TapBar: FC<MenuLinkIconI> = memo(({
  data,
  isAuth,
  route
}) => {
  const dispatch = useAppDispatch();
  const isActive = (path: string): boolean => route === path;
  const getIsAuthResolve = (isAuthGuard: boolean) => isAuthGuard ? isAuth : true;
  const onClickHandle = (isAuthResolve: boolean) => (e: MouseEvent<HTMLButtonElement>) => {
    if (isAuthResolve) {
      return;
    }
    e.preventDefault();
    dispatch(setIsShowModal({
      isShow: true,
      alias: modalsAlias.implementAuth
    }));
  };
  return <StyledFooterBar>
      <Container>
        <MenuBar>
          {data.map(({
          title,
          icon,
          path,
          isAuthGuard = false,
          caption
        }) => {
          const isAuthResolve = getIsAuthResolve(isAuthGuard);
          const captionElement = <>
                {!!caption ? <StyledCaption>{caption(title)}</StyledCaption> : title}
              </>;
          return <Button key={title} isClean className={cx(cssItem, isActive(path) && cssIsActive, !isAuthResolve && cssAuthGuardInvalid)} icon={icon} as={isAuthResolve ? "a" : undefined} href={isAuthResolve ? path : undefined} onClick={onClickHandle(isAuthResolve)}>
                {captionElement}
              </Button>;
        })}
        </MenuBar>
      </Container>
    </StyledFooterBar>;
});
TapBar.displayName = "TapBar";
import { FC } from "react"
import { useRouter } from "next/router"
import { MobileMenuStaticType } from "@/components/FooterBar/types"
import { Price } from "@/components/Products/parts"
import { PushCounter, PushCounterRow } from "@/components/PushCounter"
import { useAuth } from "@/hooks/auth"
import { useCart } from "@/hooks/cart/cart"
import { useWindowSize } from "@/hooks/useWindowSize"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { ROUTES } from "@/utils/constants"
import { TapBar } from "./TapBar"

const FooterBar: FC = () => {
  const { width } = useWindowSize()
  const isLessLg =
    width !== undefined && width <= getBreakpointVal(breakpoints.lg)

  const { cartCount: qtyCart, totalCost } = useCart()
  const { isAuth } = useAuth()

  const { route } = useRouter()

  if (!isLessLg) {
    return null
  }

  const menu: MobileMenuStaticType[] = [
    {
      title: "Главная",
      path: ROUTES.home,
      icon: "Home",
      isAuthGuard: false,
      pageAlias: "home",
    },
    {
      title: "Каталог",
      path: `${ROUTES.catalog}`,
      icon: "Catalog",
      isAuthGuard: false,
      pageAlias: "catalog",
    },
    {
      title: "Корзина",
      path: ROUTES.cart,
      icon: "ShoppingCart",
      pageAlias: "cart",
      caption: (title) =>
        totalCost > 0 ? (
          <>
            <PushCounterRow>
              <PushCounter qty={qtyCart} />
            </PushCounterRow>
            <Price price={totalCost} variant={"span"} isClean />
          </>
        ) : (
          <>{title}</>
        ),
    },
    {
      title: "Заказы",
      path: `${ROUTES.account}${ROUTES.historyAccount}`,
      icon: "Orders",
      isAuthGuard: true,
      pageAlias: "historyAccount",
    },
    {
      title: "Профиль",
      path: ROUTES.account,
      icon: "User",
      isAuthGuard: true,
      pageAlias: "account",
    },
  ]

  return <TapBar data={menu} isAuth={isAuth} route={route} />
}

export { FooterBar }
